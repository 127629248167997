<template>
    <div>
      
        <div  class="schemeDiv">
            <div class="lineDiv">
                    <div class="line"></div>
                    <span>电费折扣讯息</span>
                </div>
               <table>
                    <tr class="titleTB">
                        <th>原始单价(元/度)</th>
                        <th>折扣</th>
                        <th>折扣电价(元/度)</th>
                    </tr>
                    <tr v-for="(item,index) of schemeArr" :key="index" :class="{'selectColor':selectIndex == index+1}">
                        <td>≤ ¥ {{item.discountFirst}}</td>
                        <td class="colorText">{{item.discountPercentage}}</td>
                        <td>¥ {{item.afterDiscount}}</td>
                    </tr>
               </table>
                
        </div>

    </div>
</template>
<script>


    export default{
        name:'electricityAplan',
        data(){
            return{
                
            }
        },
        props:{
            schemeArr:{
                type:Array
            },
            selectIndex:{
                type:String
            }
        },
        methods:{
           
        },
       
    }
</script>
<style scoped>
.schemeDiv{
    background: #ffffff;
    display: flex;
    margin-top: 10px;
    flex-direction: column;
}
.selectColor{
    background: #E4F3F3;
}
.lineDiv{
    display: flex;
    align-items: center;
}

.lineDiv span{
    font-size: 36px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #333333;
    padding: 35px 20px 28px 0px;
}
.line{
    width: 10px;
    height: 34px;
    background: #06B2B2;
    border-radius: 5px;
    margin: 32px 37px 26px 26px;
    display: inline-block;
}
table{
    width: 88%;
    margin: 0 auto;
    border-spacing: 0px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    overflow: hidden;
    box-shadow: 3px 6px 19px 2px rgba(210,231,231,0.7);
}
.titleTB{
    text-align: center;
    line-height: 100px;
    background: #06B2B2;
    font-size: 35px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #FFFEFE;
}
table tr td{
    text-align: center;
    height: 98px;
}
.colorText{
    color: #039393;
}
</style>
