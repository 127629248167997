<template>
    <div>
        <van-nav-bar
            title="电费方案"
            left-arrow
            @click-left="onClickLeft"
            />
          
                <electricityAplan v-if="isShowA" :schemeArr="schemeArr" :selectIndex="selectIndex"></electricityAplan>
                <div v-else-if="isShowB">
                        <div class="basicDiv">
                    <div class="lineDiv">
                        <span>储电设备方案</span>
                    </div>
                    <!-- <div class="smallLineDiv">
                        <p>原始单价</p>
                        <input type="text" placeholder="请输入" v-model=planBdata.originalPrice >
                    </div> -->
                    <div class="smallLineDiv">
                        <p>所属行业别</p>
                        <select v-model=planBdata.industry >
                            <option v-for="(item,index) in map_industry" :key="index" :value="item.val">{{item.label}}</option>
                        </select>
                    </div>
                    <div class="smallLineDiv">
                        <p>用电时段</p>
                        <div v-for="(item,index) in time_interval" :key="index" >
                            <input type="checkbox" :value="item.val" :id="item.id" v-model=planBdata.stageNumber />
                            <label :for="item.id" class="labelText">{{item.label}}</label>
                        </div>
                    </div>
                    <!-- <div class="smallLineDiv">
                        <p>平均电费(元)</p>
                        <input type="text" v-model=planBdata.electricityFees >
                    </div> -->
                    <div v-for="(item,index) in planBdata.electricTransformer" :key="index" >
                                <div class="titleDelectDiv">
                                    <span class="title">变压器KVA({{index+1}})</span>
                                </div>
                            
                                <div class="smallLineDiv">
                                    <p>变压器(KVA)</p>
                                    <input type="text"  v-model=item.transformerKva >
                                </div>       
                                
                    </div>
                    <div class="smallLineDiv">
                        <p>变压器使用率(%)</p>
                        <input type="text"  v-model=planBdata.transformerUtilization >
                    </div>
                
                    <div class="smallLineDiv">
                        <p>场地</p>
                        <div>
                            <input type="radio" value="1" v-model=planBdata.space id="space_1"  />
                            <label for="space_1" >室内</label>
                            <input type="radio" value="2" v-model=planBdata.space id="space_2" />
                            <label for="space_2" >室外</label>
                        
                        </div>
                        
                    </div>
                    <div class="smallLineDiv">
                        <p>使用需求</p>
                        <input type="text" v-model=planBdata.programmeInfo >
                    </div>  
                    </div>
                    <div class="basicDiv">
                        <div class="smallLineDiv">
                            <p>备注</p>
                            <input type="text"  v-model=planBdata.remarkInfo >
                        </div>
                    </div >
                </div>
           
    </div>
</template>
<script>
import electricityAplan from './electricityAplan.vue'


 import {getAConclusion,getBConclusion,getLabels} from '../../network/addMes'
    export default{
        name:'UploderElectricityPlan',
        data(){
            return{
                isShowA:false,
                isShowB:false,
                schemeArr:[],
                selectIndex:'',
                planBdata:{},
                map_industry:[],
                time_interval:[]
              
            }
        },
        components:{electricityAplan},
        methods:{
            onClickLeft(){
                this.$router.back()
            },
        },
        beforeMount() {
            let programmeType = this.$route.query.plan;
                if(programmeType == 'discount_scheme'){
                    this.isShowA = true
                }else if(programmeType == 'storage'){
                    this.isShowB = true
                }
                let id = this.$route.query.id;
                if(id && programmeType == 'discount_scheme'){
                    getAConclusion(id).then((res)=>{
                        if(res.success){
                            this.schemeArr = res.data.electricDiscount
                            this.selectIndex = res.data.index
                        }
                   })
                
                }
                 if(id && programmeType == 'storage'){
                   
                    getLabels('map_industry').then((res)=>{
                        if(res.success){
                           this.map_industry = res.data
                        }
                    })
                    getLabels('time_interval').then((res)=>{
                        if(res.success){
                           this.time_interval = res.data
                        }
                    })
                    getBConclusion(id).then((res)=>{
                        if(res.success){
                           this.planBdata = res.data
                        }
                    })
                }
                
        },
       
    }
</script>
<style scoped>
.titleDelectDiv{
    height: 70px;
    position: relative;
    background: #ECF6F6;
}

.title{
    
    line-height: 70px;
    font-size: 25px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #01A4A4;
    margin: 0px 47px;
}
.basicDiv{
    background: #ffffff;
    display: flex;
    flex-direction: column;
    margin-top: 10px;
}
.lineDiv{
    display: flex;
    align-items: center;
}
.smallLineDiv{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.smallLineDiv input{
    text-align: right;
}
.smallLineDiv p{
  line-height: 80px;
    font-size: 30px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #282828;
}

.lineDiv span{
    font-size: 36px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #333333;
    margin: 33px 10px 26px 65px;
}
.smallLineDiv{
    width: 82%;
    margin: auto;
    border-top: solid 2px #F4F4F5;
}


</style>
